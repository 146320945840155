export default {
    name: 'Glowforge:BlogNavigation',
    friendlyName: 'Blog Navigation',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F7564d9458d8848809045763fe43f3dbe?width=200&height=200',
    noWrap: true,
    inputs: [
        {
            name: 'blogNavigation',
            type: 'reference',
            model: 'blog-nav',
        },
        {
            name: 'mobileDropdownOpenerText',
            friendlyName: 'Mobile Dropdown Opener Text',
            type: 'text',
        },
    ],
};
