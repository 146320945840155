import {
  SubcategoryText,
  MobileInnerDropdown,
} from '../../blog-navigation.styles';
import Link from '@glowforge/gf-styled/src/components/link';

const Dropdown = ({ opened, subnavItems }) => {
  return (
    <MobileInnerDropdown opened={opened} className='mobile-inner-dropdown'>
      {subnavItems?.map((item, index) => {
        const { navText, navLink } = item || {};
        return (
          navText && navLink && (
            <Link key={navLink + navText + index} href={navLink} className="navigation-link-wrapper">
              <SubcategoryText>{navText}</SubcategoryText>
            </Link>
          )
        );
      })}
    </MobileInnerDropdown>
  );
};

export default Dropdown;
