import React from 'react';
import PropTypes from 'prop-types';
import { withChildren } from '@builder.io/react/lite';

import {
  Background,
  FloatQuote,
  ImageContainer,
  TextContainer,
  TextContentWrapper,
  Wrapper,
} from './staggered-card.styles';

/**
 * Probably not the best way to do this, but was quick.
 */
const backgroundPatterns = {
  'magic dots': 'https://images.ctfassets.net/ljtyf78xujn2/4JrKZf5vVLJR8Uup7jwNjM/0db67c3ec98c8f9454a8ae30141bb963/magic-dots.png',
  'wood': 'https://images.ctfassets.net/ljtyf78xujn2/3yP9UoQ7nVhYwhOC3pAtfc/365c25246bc10bce4ce8bb13a8c66120/wood-seamless-pattern-1-quality_1_.png',
  'honeycomb': 'https://images.ctfassets.net/ljtyf78xujn2/5rpgfgz04R4sal1Co992Ar/de427ac1b57f399d0041961e14f5a0ab/honeycomb-seamless-pattern-saddle.png',
}

export const StaggeredCard = ({
  variant,
  builderBlock,
  children,
  image,
  title,
  bodyContent,
  backgroundImage,
  backgroundColor,
  button,
  showQuote,
  minHeightMobile,
  minHeightDesktop,
  ...delegated
}) => {
  const renderImage = image ? (
      <ImageContainer variant={variant}>
        <img src={image} alt={title} />
      </ImageContainer>
  ) : null;

  // We want to provide the complimentary color for our backgroundColor.
  const quoteColor =
    showQuote && backgroundColor && backgroundColor.split('.').length === 2
      ? `${backgroundColor.split('.')[0]}.lightest`
      : 'magic.lightest';

  return (
    <Wrapper variant='body' {...delegated}>
      {renderImage}
      <TextContainer
        side={variant}
        backgroundImage={backgroundImage}
        backgroundColor={backgroundColor}
        backgroundImagePosition='alignLeft'
        minHeightMobile={minHeightMobile}
        minHeightDesktop={minHeightDesktop}
        showOverlay={false}>
        {showQuote ? <FloatQuote color={quoteColor}>&ldquo;</FloatQuote> : null}
        <Background backgroundImage={backgroundPatterns[backgroundImage]} />
        <TextContentWrapper variant={variant}>{children}</TextContentWrapper>
      </TextContainer>
    </Wrapper>
  );
};

StaggeredCard.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  bodyContent: PropTypes.string,
  builderBlock: PropTypes.shape({
    id: PropTypes.string,
  }),
  button: PropTypes.shape({}),
  children: PropTypes.node,
  image: PropTypes.string,
  minHeightDesktop: PropTypes.string,
  minHeightMobile: PropTypes.string,
  showQuote: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['left-image', 'right-image']),
};

StaggeredCard.defaultProps = {
  backgroundColor: null,
  backgroundImage: null,
  bodyContent: null,
  builderBlock: {
    id: '',
  },
  button: null,
  children: null,
  image: null,
  minHeightDesktop: '400px',
  minHeightMobile: '250px',
  showQuote: false,
  title: null,
  variant: 'right-image',
};

export { ImageContainer, TextContainer };

export const StaggeredCardWithChildren = withChildren(StaggeredCard);
export default StaggeredCardWithChildren;
