import {
  DesktopNavigation,
  DesktopNavigationWrapper,
  DesktopCategoryNavigationContainer,
} from '../../blog-navigation.styles';
import NavigationLink from './navigation-link';
import Dropdown from './dropdown';
import Link from '@glowforge/gf-styled/src/components/link';
import SearchBar from '../search/search-bar';
const DesktopNav = ({ blogNavItems }) => {
  return (
    <DesktopNavigation className='desktop-navigation'>
      <DesktopNavigationWrapper className='desktop-navigation-wrapper'>
        {blogNavItems?.map((item, index) => {
          const isSubNav = item?.subnav ?? false;
          return (
            <DesktopCategoryNavigationContainer
              className='category-navigation-container'
              key={item.navText + item.navLink + index}>
              <Link href={item?.navLink}>
                <NavigationLink
                  item={item}
                  isSubNav={isSubNav}
                />
                {isSubNav && <Dropdown item={item} />}
              </Link>
            </DesktopCategoryNavigationContainer>
          );
        })}
      </DesktopNavigationWrapper>
      <SearchBar />
    </DesktopNavigation>
  );
};

export default DesktopNav;
