import { builder } from '@builder.io/react/lite';
export const fetchFilteredArticles = async (typeList, modelId, addedCount, ARTICLES_PER_PAGE, q, isServer=false) => {
  let model = '';
  if (typeList === 'search' && !q) {
    return;
  }
  switch (typeList) {
    case 'category':
      model = 'data.category.id';
      break;
    case 'author':
      model = 'data.author.id';
      break;
    case 'search':
      model = [
        {
          'data.title': {
            $regex: q?.toLocaleLowerCase(),
            $options: 'i',
          },
        },
        {
          'data.summary': {
            $regex: q?.toLocaleLowerCase(),
            $options: 'i',
          },
        },
      ];
      break;
  }

  const query = typeList === 'search' ? { $or: model } : { [model]: { $eq: modelId } };

  try {
    const fetchedArticles = await builder.getAll('blog-article', {
      options: {
        noTargeting: true,
        enrich: true,
        offset: (addedCount - 1) * ARTICLES_PER_PAGE,
      },
      fields:
        'data.title,data.image,data.summary,data.slug,data.date,data.author.value.data.name,data.author.value.data.slug,data.category.value.data.name,data.category.value.data.slug',
      query,
      limit: ARTICLES_PER_PAGE + 1,
    });
    if (isServer) {
      const articlesToSet = fetchedArticles.slice(0, ARTICLES_PER_PAGE);
      const hasMore = fetchedArticles.length > ARTICLES_PER_PAGE;
      return { articlesToSet, hasMore }
    }
    return fetchedArticles;
  } catch (err) {
    console.log('Failed to fetch', err);
  }
};
