import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { Video, EmbedVideo } from './video.styles';
import useIntersectionObserver from '../util/useInterSectionObserver';

const InlineVideo = ({
  mp4Src,
  webmSrc,
  embedSrc,
  thumbnailSrc,
  attributes,
  autoplay,
  isFeatureCarousel
}) => {
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  if (embedSrc.length > 0) {
    // Needed for the never ending loop
    const embedId = embedSrc.substring(
      embedSrc.indexOf('bed/') + 4, 
      embedSrc.indexOf('?')
    );
    return (
      <EmbedVideo isFeatureCarousel={isFeatureCarousel}>
        <iframe
          width='100%'
          height='100%'
          src={`${embedSrc}&mute=1&autoplay=${autoplay && '1'}&loop=1&playlist=${embedId}`}
          title='YouTube video player'
          // eslint-disable-next-line max-len
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
        />
      </EmbedVideo>
    );
  }
  return (
  <Video
    ref={ref}
    poster={thumbnailSrc}
    playsInline
    autoPlay={autoplay}
    loop='loop'
    muted
    width='100%'
    height='100%'
    {...attributes}>
    {isVisible ? (
      <>
        {mp4Src ? <source src={mp4Src} type='video/mp4' /> : null}
        {webmSrc ? <source src={webmSrc} type='video/mp4' /> : null}
      </>
    ): null}
  </Video>
)};

InlineVideo.propTypes = {
  attributes: PropTypes.shape({}),
  autoplay: PropTypes.bool,
  embedSrc: PropTypes.string,
  mp4Src: PropTypes.string,
  thumbnailSrc: PropTypes.string,
  webmSrc: PropTypes.string,
};

InlineVideo.defaultProps = {
  attributes: {},
  autoplay: true,
  embedSrc: '',
  mp4Src: '',
  thumbnailSrc: '',
  webmSrc: ''
};

export default InlineVideo;
