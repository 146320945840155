import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Markdown from '@glowforge/gf-styled/src/components/markdown';
import css from '@styled-system/css';

import { UnstyledText, LinkWrapper } from './text.styles';

const Text = ({
  as: As,
  asMarkdown,
  attributes,
  color,
  link = null,
  newWindow,
  text,
  textAlign,
  variant,
}) => {
  const theme = useTheme();
  
  const ElementType = useCallback(({children, ...props}) => {
    const element = (
    <As {...props}>
      {children}
    </As>);
    return element;
    }, []);

  const LinkElementType = useCallback(({children, ...props}) => (
    <a {...props} {...attributes}>{children}</a>
  ), [attributes]);

  const textStyle = {};
  textStyle.color =
    color && typeof color === 'string' && color !== 'custom'
      ? css({
          color,
        })(theme)?.color ?? undefined
      : undefined;
  textStyle.textAlign =
    textAlign && typeof textAlign === 'string' && textAlign !== 'custom' ? textAlign : undefined;

  let Component = null;
  
  if (asMarkdown) {
    Component = (
      <Markdown
        source={text}
        element='span'
        {...(!link && attributes)}
        $color={textStyle.color}
        $textAlign={textStyle.textAlign}
      />
    );
  } else if (variant === 'custom') {
    Component = (
      <ElementType {...(!link && attributes)}>
        {text}
      </ElementType>
    );
  } else {
    Component = (
      <UnstyledText
        as={ElementType}
        variant={variant}
        $color={textStyle.color}
        $textAlign={textStyle.textAlign}
        {...(!link && attributes)}>
        {text}
      </UnstyledText>
    );
  }

  if (link) {
    let linkDetails = {};
    if (newWindow) {
      linkDetails = {
        rel: 'noreferrer',
        target: '_blank',
      };
    }

    return (
      <LinkWrapper
        as={LinkElementType}
        href={link}
        {...linkDetails}
        style={textStyle}>
        {Component}
      </LinkWrapper>
    );
  }

  return Component;
};

Text.propTypes = {
  as: PropTypes.string,
  asMarkdown: PropTypes.bool,
  attributes: PropTypes.shape({}),
  color: PropTypes.string,
  link: PropTypes.string,
  newWindow: PropTypes.bool,
  text: PropTypes.string,
  textAlign: PropTypes.string,
  variant: PropTypes.string,
};
Text.defaultProps = {
  as: 'p',
  asMarkdown: false,
  attributes: {},
  color: null,
  link: null,
  newWindow: null,
  text: null,
  textAlign: null,
  variant: 'paragraph',
};

export default Text;
