import {
  DesktopNavigationLink,
  DesktopDropdownContainer,
  DesktopInnerDropdownContainer,
  CategoryText,
} from '../../blog-navigation.styles';

const Dropdown = ({ item }) => {
  const subnavItems = item?.subnav?.value?.data?.blogNavItems;
  return (
    <DesktopDropdownContainer className='dropdown-container'>
      <DesktopInnerDropdownContainer className='dropdown-wrapper'>
        {subnavItems?.map((subnav, index) => {
          const { navText, navLink } = subnav || {};
          return (
            navText && navLink && (
              <DesktopNavigationLink key={navLink + index} href={navLink}>
                <CategoryText>{navText}</CategoryText>
              </DesktopNavigationLink>
            ))
        })}
      </DesktopInnerDropdownContainer>
    </DesktopDropdownContainer>
  );
};
export default Dropdown;
