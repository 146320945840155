/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import smoothscroll from 'smoothscroll-polyfill';
import SVG from '../svg';

import {
  Wrapper,
  ScrollWrapper,
  ArrowWrapper,
  ArrowButton,
} from './horizontal-scroll.styles';

/**
 * Horizontal Scroller that utilizes the native scroll behaviour and allows smooth scrolling
 * with smoothscroll polyfill. Horizontal Scroll makes a best guess for scroll distances by
 * dividing the total scroll area by the length of the children array passed. This works under the
 * assumption that children are equally sized and evenly spaced.
 */
class HorizontalScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackArrow: false,
      showForwardArrow: false,
    };
    this.moveBack = this.moveBack.bind(this);
    this.moveForward = this.moveForward.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.throttledHandleScroll = throttle(this.handleScroll, 200);
  }

  componentDidMount() {
    // polyfill smooth scrolling api
    // https://github.com/iamdustan/smoothscroll
    smoothscroll.polyfill();
  }

  /**
   * Throttled handle scroll. Is throttled in constructor.
   */
  handleScroll() {
    const { scrollWidth, scrollLeft, offsetWidth } = this.scrollArea;
    const scrolledLeft = scrollLeft < 10;
    const scrolledRight = scrollLeft > scrollWidth - offsetWidth - 10;
    this.setState({
      showForwardArrow: !scrolledRight,
      showBackArrow: !scrolledLeft,
    });
  }

  setScrollRef = (scrollArea) => {
    if (!scrollArea) return;
    this.scrollArea = scrollArea;
    let { children, scrollStepMultiply, isBlog } = this.props;

    // Get the children from the custom blog article component
    if (isBlog){
      children = children[0]?.props?.children?.props?.block?.component?.options?.articles || Array(10).fill(null);
    }
    
    // Make best guess for element widths: total scroll width / total number of children
    this.scrollStepWidth = Math.ceil(scrollArea.scrollWidth / children?.length) * scrollStepMultiply;
    this.handleScroll();
  };

  moveBack() {
    this.scrollArea.scrollBy({
      top: 0,
      left: -1 * this.scrollStepWidth,
      behavior: 'smooth',
    });
  }

  moveForward() {
    this.scrollArea.scrollBy({
      top: 0,
      left: this.scrollStepWidth,
      behavior: 'smooth',
    });
  }

  render() {
    const { showBackArrow, showForwardArrow } = this.state;

    const backArrow = (
      <ArrowWrapper
        isVisible={showBackArrow}
        hideNavigationArrowsMobile={this.props.hideNavigationArrowsMobile}
        back>
        <ArrowButton
          onClick={this.moveBack}
          direction={'backward'}
          aria-label='Move Carousel backward'>
          <SVG id='chevron-light' />
        </ArrowButton>
      </ArrowWrapper>
    );

    const forwardArrow = (
      <ArrowWrapper
        hideNavigationArrowsMobile={this.props.hideNavigationArrowsMobile}
        isVisible={showForwardArrow}>
        <ArrowButton
          onClick={this.moveForward}
          direction={'forward'}
          aria-label='Move Carousel forward'>
          <SVG id='chevron-light' />
        </ArrowButton>
      </ArrowWrapper>
    );

    const { children, className } = this.props;
    return (
      <Wrapper className={`fullWidth ${className}`}>
        <ScrollWrapper
          ref={this.setScrollRef}
          onScroll={this.throttledHandleScroll}>
          {children}
        </ScrollWrapper>
        {backArrow}
        {forwardArrow}
      </Wrapper>
    );
  }
}

HorizontalScroll.propTypes = {
  children: PropTypes.node,
  showMobileArrows: PropTypes.bool,
  scrollStepMultiply: PropTypes.number,
  className: PropTypes.string,
};

HorizontalScroll.defaultProps = {
  children: null,
  showMobileArrows: false,
  scrollStepMultiply: 1,
  className: '',
};

export default HorizontalScroll;
