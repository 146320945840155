import {
  HeroContainer,
  BackgroundImageContainer,
  HeroContentContainer,
  HeroContentWrapper,
  HeroTitle,
  HeroDescription,
  HeroMainWrapper,
  HeroDescriptionMobile,
  AuthorLink,
  DateText,
  AuthorDateWrapper,
} from '../blog-hero.styles';
import Button from '@glowforge/gf-styled/src/components/button';
import { Overlay } from '../../hero-banner/hero-banner.styles';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { dateConverter } from '../../util/dateConverter';

const HeroSection = ({ blog }) => {
  const {
    textColor,
    backgroundPositionDesktop,
    backgroundPositionMobile,
    authorDatePosition,
    showSummary,
    showOverlay,
    blogRef,
    backgroundImageDesktop,
    backgroundImageMobile,
    overlayOpacityLevel
  } = blog;
  const { title, summary, slug, author, date } = blogRef?.value?.data || {};
  const { name: authorName, slug: authorSlug } = author?.value?.data || {};

  const desktopSummary = summary?.length > 450 ? summary?.substring(0, 450) + '...' : summary;
  const mobileSummary = summary?.length > 220 ? summary?.substring(0, 220) + '...' : summary;

  const desktopImage = backgroundImageDesktop ? backgroundImageDesktop : blogRef?.value?.data?.image;
  const mobileImage = backgroundImageMobile ? backgroundImageMobile : backgroundImageDesktop ? backgroundImageDesktop : blogRef?.value?.data?.image;

  return (
    title && (
      <HeroContainer>
        <HeroContentContainer isMain={true}>
          <HeroContentWrapper showSummary={showSummary}>
            <HeroTitle className='blog-hero-title' textColor={textColor}>{title}</HeroTitle>
            {summary && showSummary && (
              <>
                <HeroDescription className='blog-hero-description-desktop' textColor={textColor}>
                  {desktopSummary}
                </HeroDescription>
                <HeroDescriptionMobile className='blog-hero-description-mobile' textColor={textColor}>
                  {mobileSummary}
                </HeroDescriptionMobile>
              </>
            )}
            {date && authorName && authorSlug && (
              <AuthorDateWrapper authorDatePosition={authorDatePosition} isMain={true} showSummary={showSummary} className='author-date-wrapper-main'>
                <DateText className='date-text' textColor={textColor}>{dateConverter(date)}</DateText>
                <AuthorLink
                  textColor={textColor}
                  href={`/blog/author/${authorSlug}`}
                  className='author-link'>
                  {authorName}
                </AuthorLink>
              </AuthorDateWrapper>
            )}
            {slug && 
            <Button
              type='button'
              variant='primary'
              className='blog-hero-read-more'
              href={`/blog/article/${slug}`}
              icon={<SVG id='long-arrow-right' noWidth />}>
              Read More
            </Button>}
          </HeroContentWrapper>
        </HeroContentContainer>
        <BackgroundImageContainer
          desktopImage={desktopImage}
          mobileImage={mobileImage}
          backgroundPositionDesktop={backgroundPositionDesktop}
          backgroundPositionMobile={backgroundPositionMobile}
        />
        {showOverlay && <Overlay opacity={overlayOpacityLevel} />}
      </HeroContainer>
    )
  );
};
const BlogHeroMain = ({ blogRefs }) => {
  return (
    blogRefs && (
      <HeroMainWrapper>
        {blogRefs?.length >= 2 ? (
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={false}
            observeParents={true}
            observer={true}
            modules={[Autoplay, Navigation, Pagination]}
            speed={400}
            effect='slide'
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            loop={true}
            pagination={{ clickable: true }}>
            {blogRefs.map((blog) => {
              return (
                <SwiperSlide key={blog?.blogRef?.id}>
                  <HeroSection blog={blog} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <HeroSection blog={blogRefs[0]} />
        )}
      </HeroMainWrapper>
    )
  );
};

export default BlogHeroMain;
