import BlogCard from '../blog-articles-ref/blog-article-card';
import PropTypes from 'prop-types';

const BlogRecentPosts = ({ attributes, ...props }) => {
  const latestArticles = props?.builderState?.content?.data?.latestArticles || [];
  return latestArticles?.map((article, index) => (
    <BlogCard
      {...attributes}
      article={article}
      key={article?.data?.slug + index}
      isInCarousel={true}
    />
  ));
};

BlogRecentPosts.propTypes = {
  latestArticles: PropTypes.arrayOf(PropTypes.shape({})),
};

BlogRecentPosts.defaultProps = {
  latestArticles: [],
};

export default BlogRecentPosts;
