import React from 'react';
import CookieAnalytics from './cookie-analytics';
import PageAnalytics from './page-analytics';
import PageviewsAndTimeAnalytics from './pageviews-and-time-analytics';

const Analytics = () => (
  <>
    <PageAnalytics />
    <PageviewsAndTimeAnalytics />
    <CookieAnalytics/>
  </>
);

export default Analytics;
