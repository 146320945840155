import getConfig from 'next/config';
import { createClient } from 'contentful';
import get from 'lodash/get';
import COMMON from '../route-constants';

// TODO: [STORE-4143] Split Contentful API
// in to multiple files to allow code splitting

const { publicRuntimeConfig } = getConfig();
/** Copied from ecomm-front-end/app/api/contentful-client.js */

let contentfulClient;

async function initClient() {
  contentfulClient = createClient(publicRuntimeConfig.contentful);
  return contentfulClient.getSpace().then((contentfulSpace) => contentfulSpace);
}

/**
 * @returns {import('contentful').ContentfulClientApi} contentfulClientApi
 */
export function getContentfulClient() {
  if (!contentfulClient) {
    initClient();
  }
  return contentfulClient;
}

/**
 * @param {import('contentful').EntryCollection<any>} entries
 */
export function unpackPages(entries) {
  if (!entries.items || entries.items.length === 0) {
    throw new Error(COMMON.PAGE_ERRORS.PAGE_NOT_FOUND);
  }
  return entries.items;
}

/**
 * Normalizes the data returned from Contentful for an entry
 * representing a single page.
 * @param {import('contentful').EntryCollection<any>} entries
 */
export function unpackPage(entries) {
  return unpackPages(entries)[0];
}

export const getPage = (id) =>
  getContentfulClient()
    .getEntries({
      include: 8,
      content_type: 'page',
      'fields.id': id,
    })
    .then(unpackPage);

export const getStandalonePageBySlug = (slug) =>
  getContentfulClient()
    .getEntries({
      include: 4,
      content_type: 'page',
      'fields.slug': slug,
    })
    .then((entries) => {
      /*
      Contentful client doesn't support filtering for more than one content type
    */
      const page = unpackPage(entries);
      if (get(page, 'fields.pageType') !== 'standalone') {
        throw new Error(COMMON.PAGE_ERRORS.PAGE_NOT_FOUND);
      }
      return page;
    });

export const getLegalPage = (type) =>
  getContentfulClient()
    .getEntries({
      include: 4,
      content_type: 'legalPage',
      'fields.type': type,
    })
    .then(unpackPage);

export const getGlobalFooterRebrand = () =>
  getContentfulClient()
    .getEntries({
      include: 4,
      content_type: 'section',
      'fields.id': 'global-footer-rebrand',
    })
    .then((entries) => (entries.items.length ? entries.items[0] : {}));

export const getDefaultMetadata = () =>
  getContentfulClient()
    .getEntries({
      include: 4,
      content_type: 'pageMetadata',
      'fields.id': 'default-metadata',
    })
    .then((entries) => (entries.items.length ? entries.items[0] : {}));

/* TODO: refactor getDefaultMetadata to compose getCustomMetadata */
export const getCustomMetadata = (id) =>
  getContentfulClient()
    .getEntries({
      include: 4,
      content_type: 'pageMetadata',
      'fields.id': id,
    })
    .then((entries) => (entries.items.length ? entries.items[0] : {}));

export const getJobs = () =>
  getContentfulClient()
    .getEntries({
      include: 1,
      content_type: 'job',
    })
    .then(entries => {
      if (!entries.items || entries.items.length < 1) {
        return [];
      }
      return unpackPages(entries);
    });

export const getJobDescriptionPage = (slug) =>
  getContentfulClient()
    .getEntries({
      include: 1,
      content_type: 'job',
      'fields.slug': slug,
    })
    .then(unpackPage);

export const getTextString = (id) =>
  getContentfulClient()
    .getEntries({
      include: 1,
      content_type: 'textString',
      'fields.id': id,
    })
    .then(unpackPage);

export const getLatestImprovements = ({
  isLoggedIn = false,
  isAdmin = false,
  limit = 10,
  page = 1,
} = {}) => {
  const visibility = ['public'];
  if (isLoggedIn) visibility.push('logged-in-only');
  if (isAdmin) visibility.push('admin-only');

  let skip = 0;
  // guard against faulty values
  if (!Number.isNaN(page) && page > 1) {
    skip = (page - 1) * limit;
  }

  const now = new Date().toISOString();

  return getContentfulClient().getEntries({
    include: 8,
    limit,
    skip,
    content_type: 'article',
    'fields.articleType': 'Latest Improvements Post',
    order: '-fields.publicationDate',
    // Even though the api docs say 'array', they mean comma-separated string.
    'fields.visibility[in]': visibility.join(', '),
    // Only show published articles, unless an admin is requesting it.
    ...(!isAdmin ? { 'fields.publicationDate[lte]': now } : null),
  });
};

export const getArticleBySlug = (
  slug,
  { isLoggedIn = false, isAdmin = false } = {}
) => {
  const visibility = ['public'];
  if (isLoggedIn) visibility.push('logged-in-only');
  if (isAdmin) visibility.push('admin-only');

  const now = new Date().toISOString();

  return getContentfulClient()
    .getEntries({
      include: 8,
      content_type: 'article',
      'fields.slug[in]': slug,
      // Even though the api docs say 'array', they mean comma-separated string.
      'fields.visibility[in]': visibility.join(', '),
      // Only show published articles, unless an admin is requesting it.
      ...(!isAdmin ? { 'fields.publicationDate[lte]': now } : null),
    })
    .then((entries) => {
      if (!entries.items || entries.items.length === 0) {
        throw new Error(COMMON.PAGE_ERRORS.PAGE_NOT_FOUND);
      }
      return entries.items[0];
    });
};
