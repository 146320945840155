const backgroundPositions = [
    'center',
    'top center',
    'bottom center',
    'right',
    'right top',
    'right bottom',
    'left',
    'left top',
    'left bottom',
];

const heroPages = [
    'main',
    'article',
    'category',
    'author'
]

const authorTitleColors = [
    'white',
    'black'
]

const authorDatePosition = [
    'left',
    'right'
]

export default {
    name: 'Glowforge:BlogHero',
    friendlyName: 'Blog Hero',
    image: 'https://cdn.builder.io/api/v1/image/assets%2F69fac0673d2644f0a0a962e1cb3045ef%2F8878d560fdc54a65a45313b9f0d7f46a?width=200&height=200',
    noWrap: true,
    inputs: [
        {
            name: 'page',
            friendlyName: 'Page',
            type: 'string',
            enum: heroPages,
        },
        {
            name: 'blogRefs',
            showIf: (options) => options.get('page') === 'main',
            friendlyName: 'Blog Refs',
            type: 'list',
            helperText: 'This applies only for Main Page, leave blank if component is used on author/categories.',
            subFields: [
                {
                    name: 'blogRef',
                    type: 'reference',
                    model: 'blog-article',
                },
                {
                    name: 'textColor',
                    type: 'string',
                    defaultValue: authorTitleColors[0],
                    enum: authorTitleColors,
                },
                {
                    name: 'backgroundImageDesktop',
                    friendlyName: 'Background Image Desktop',
                    type: 'file',
                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
                },
                {
                    name: 'backgroundImageMobile',
                    friendlyName: 'Background Image Mobile',
                    type: 'file',
                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
                },
                {
                    name: 'backgroundPositionDesktop',
                    friendlyName: 'Background Position Desktop',
                    type: 'string',
                    defaultValue: backgroundPositions[0],
                    enum: backgroundPositions,
                },
                {
                    name: 'backgroundPositionMobile',
                    friendlyName: 'Background Position Mobile',
                    type: 'string',
                    defaultValue: backgroundPositions[0],
                    enum: backgroundPositions,
                },
                {
                    name: 'showSummary',
                    type: 'boolean',
                    defaultValue: true,
                },
                {
                    name: 'authorDatePosition',
                    type: 'string',
                    defaultValue: authorDatePosition[0],
                    enum: authorDatePosition,
                },
                {
                    name: 'showOverlay',
                    type: 'boolean',
                    defaultValue: true,
                },
                {
                    name: 'overlayOpacityLevel',
                    friendlyName: 'Overlay Opacity Level',
                    type: 'number',
                    defaultValue: 30,
                    min: 0,
                    max: 100,
                    helperText: 'Opacity level - 0...100'
                }
            ],
        },
        {
            name: 'textColor',
            showIf: (options) => options.get('page') !== 'main',
            type: 'string',
            defaultValue: authorTitleColors[0],
            enum: authorTitleColors,
        },
        {
            name: 'backgroundImageDesktop',
            showIf: (options) => options.get('page') !== 'main',
            friendlyName: 'Background Image Desktop',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
        },
        {
            name: 'backgroundImageMobile',
            showIf: (options) => options.get('page') !== 'main',
            friendlyName: 'Background Image Mobile',
            type: 'file',
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'webp'],
        },
        {
            name: 'backgroundPositionDesktop',
            showIf: (options) => options.get('page') !== 'main',
            friendlyName: 'Background Position Desktop',
            type: 'string',
            defaultValue: backgroundPositions[0],
            enum: backgroundPositions,
        },
        {
            name: 'backgroundPositionMobile',
            showIf: (options) => options.get('page') !== 'main',
            friendlyName: 'Background Position Mobile',
            type: 'string',
            defaultValue: backgroundPositions[0],
            enum: backgroundPositions,
        },
        {
            showIf: (options) => options.get('page') !== 'main',
            name: 'showOverlay',
            type: 'boolean',
            defaultValue: true,
        },
        {
            showIf: (options) => options.get('page') !== 'main',
            name: 'overlayOpacityLevel',
            friendlyName: 'Overlay Opacity Level',
            type: 'number',
            defaultValue: 30,
            min: 0,
            max: 100,
            helperText: 'Opacity level - 0...100'
        },
    ],
};
