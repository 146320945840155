/**
 * @type {import('@builder.io/sdk/dist/src/builder.class').Component}
 */
const videoConfig = {
  name: 'Glowforge:Video',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2F81a15681c3e74df09677dfc57a615b13',
  noWrap: true,
  defaultStyles: {
    flexDirection: 'row',
  },
  inputs: [
    {
      name: 'mp4Src',
      friendlyName: 'MP4 Video',
      helperText: 'This should be a small, silent-autoplay optimized video. See https://glowforge.atlassian.net/l/cp/KDHLumQu',
      allowedFileTypes: ['mp4'],
      type: 'file',
    },
    {
      name: 'webmSrc',
      friendlyName: 'webm Video',
      helperText: 'This should be a small, silent-autoplay optimized video. See https://glowforge.atlassian.net/l/cp/KDHLumQu',
      allowedFileTypes: ['webm'],
      type: 'file',
    },{
      name: 'embedSrc',
      friendlyName: 'Youtube Link',
      helperText: 'This should be a link that lets us embed a youtube video',
      type: 'url'
    },
    {
      friendlyName: 'Thumbnail Image',
      helperText: 'This image should be a small file-size placeholder while the video loads. https://glowforge.atlassian.net/l/cp/KDHLumQu',
      name: 'thumbnailSrc',
      type: 'file',
      allowedFileTypes: ['png', 'jpg'],
    },
    {
      name: 'autoplay',
      type: 'boolean',
      defaultValue: true
    },
  ],
};

export default videoConfig;
