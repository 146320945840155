import  { useState } from 'react';
import {
  NavigationIconWrapper,
  NavigationLinkWrapper,
  CategoryText,
  DropdownIcon,
  MobileRowContainer,
  IconTextLink,
} from '../../blog-navigation.styles';
import { Image as BuilderImage } from '@builder.io/react';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';
import Dropdown from './inner-dropdown';

const InnerRowDropdown = ({ item, isSubNav }) => {
  const subnavItems = item?.subnav?.value?.data?.blogNavItems;
  const [opened, setOpened] = useState(false);
  const handleClick = () => isSubNav ? setOpened((state) => !state) : null;
  return (
    <MobileRowContainer
      onClick={handleClick}
      opened={opened}
      className='mobile-inner-row-container'>
      <NavigationLinkWrapper>
        {item?.navLink && item?.navText && (
          <IconTextLink href={item.navLink} onClick={(e) => e.stopPropagation()} className="navigation-link-wrapper">
            {item?.image && (
              <NavigationIconWrapper>
                <BuilderImage
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  lazy='false'
                  image={item?.image}
                />
              </NavigationIconWrapper>
            )}
            <CategoryText>{item?.navText}</CategoryText>
          </IconTextLink>
        )}

        {isSubNav && (
          <DropdownIcon className='icon'>
            <SVG
              id='chevron-down'
              fill='#000'
              stroke='#000'
              width={10}
              height={10}
            />
          </DropdownIcon>
        )}
      </NavigationLinkWrapper>
        
      {subnavItems?.length > 0 && (
        <Dropdown
          opened={opened}
          subnavItems={subnavItems}
          className='mobile-inner-dropdown'
        />
      )}
    </MobileRowContainer>
  );
};

export default InnerRowDropdown;
