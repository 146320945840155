import styled from 'styled-components';
import Link from '@glowforge/gf-styled/src/components/link';


const DesktopNavigation = styled('nav')`   
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    border-top: 1px solid #0000003d;
    border-bottom: 1px solid #0000003d;
    position: relative;
    z-index: 9;

    @media ${props => props.theme.queries.navbarBreakpointDown} {
        display: none;
        border-top: 0;
        border-bottom: 0;
        flex-direction: column;
    }
`;

const MobileNavigation = styled('nav')`
    display: none;
    @media ${props => props.theme.queries.navbarBreakpointDown} {
        flex-direction: column;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 9;
    }
`;

const DesktopNavigationWrapper = styled('ul')`   
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    column-gap: 32px;
    row-gap: 12px;
    list-style-type: none;

    @media screen and (max-width: 1200px){
        max-width: 65%;
        flex-wrap: wrap;
    }

    @media ${props => props.theme.queries.navbarBreakpointDown} {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        align-items: baseline;
    }
`;

const DesktopCategoryNavigationContainer = styled('li')`
    width: fit-content;
    position: relative;
    
     @media ${props => props.theme.queries.navbarBreakpoint} {
      &:hover {
        .dropdown-container {
           pointer-events: all;
           opacity: 1;
           z-index: 9;
        }
        .dropdown-wrapper{
           opacity: 1;
           transform: translateY(0);
        }

        .navigation-link-wrapper .icon svg{
          fill: #26B8CE;
          stroke: #26B8CE;
          transform: rotate(180deg);
        }
      }
    }
    

     @media ${props => props.theme.queries.navbarBreakpointDown} {
        width: 100%;
    }
`

const NavigationIconWrapper = styled('div')`
    display: flex;
    align-items: center;
    max-width: 18px;
    min-width: 18px;
    height: 100%;
`

const DesktopDropdownContainer = styled('div')`
    position: absolute;
    height: 100%;
    position: absolute;
    width: max-content;
    min-width: 200px;
    top: 100%;
    pointer-events: none;
    background: #ffff;
    opacity: 0;
`

const DesktopInnerDropdownContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 15px 15px 25px;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0 0.9rem 0.9375rem #00000033;
    margin-top: 15px;
    opacity: 0;
    transform: translateY(-1.5rem);
    transition: transform .2s ease, opacity .1s ease;
`

const NavigationLinkWrapper = styled('div')`
    display: flex;
    gap: 7px;
    align-items: center;
    height: 20px;
    font-size: 18px;

    @media ${props => props.theme.queries.navbarBreakpointDown} {
        height: 100%;
        position: relative;
        padding: 0;
        cursor: pointer;
    }
`
const IconTextLink = styled(Link)`
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      gap: 7px;
`

const DesktopNavigationLink = styled(Link)`   
    letter-spacing: -0.4px;
    line-height: 1;
`;

const CategoryText = styled('p')`
   font-size: 14px;
   @media ${props => props.theme.queries.navbarBreakpointDown} {
    font-size: 18px;
  }
`

const SubcategoryText = styled('p')`
   font-size: 13px;
   @media ${props => props.theme.queries.navbarBreakpointDown} {
    font-size: 16px;
  }
`

const DropdownIcon = styled("div")`
  border: none;
  background: transparent;
  padding: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 3px;

  @media ${props => props.theme.queries.navbarBreakpointDown} {
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    padding-top: 5px;
    align-items: flex-start;
    height: 100%;
    margin-left: 0;
  }
  svg {
    transition: all .2s ease;
    @media ${props => props.theme.queries.navbarBreakpointDown} {
      width: 15px;
      height: 15px;
    }
  }
`

const MobileDropdownOpener = styled('div')`
    display: none;
     @media ${props => props.theme.queries.navbarBreakpointDown} {
      width: 100%;
      margin: 0 auto;
      padding: 15px 0;
      border-top: 1px solid #0000003d;
      border-bottom: 1px solid #0000003d;
      display: flex;
      justify-content: center;
      align-items: baseline;
      gap: 10px;
      cursor: pointer;
      
      svg {
      transition: all .2s ease;
      width: 15px;
      height: 15px;
      transform:  ${props => props.expanded && 'rotate(180deg)'}
    }
`

const MobileNavigationWrapper = styled('div')`
    display: none;
      @media ${props => props.theme.queries.navbarBreakpointDown} {
        display: block;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: -1;
        background: #FFFFFF;
        height: auto;
        max-height: ${props => props.expanded ? '100vh' : '0'};
        overflow-y: scroll;
        padding-bottom: ${props => props.expanded ? '20px' : '0'};
        transition: max-height 0.25s ease-in-out, padding 0.25s ease-in-out;
    }
`

const MobileDropdownContent = styled('ul')`
  display: none;
   @media ${props => props.theme.queries.navbarBreakpointDown} {
      display: flex;
      flex-direction: column;
      list-style-type: none;
  }
`

const MobileRowContainer = styled('li')`
    display: none;
    @media ${props => props.theme.queries.navbarBreakpointDown} {
        display: block;
        height: 100%;
        width: 100%;
        border-bottom: 1px solid #0000003d;
        padding: 10px;
        position: relative;
        svg{
          transform: ${props => props.opened && 'rotate(180deg)'};
      }
    }
  }
`

const MobileInnerDropdown = styled('div')`
  display: none;
    @media ${props => props.theme.queries.navbarBreakpointDown} {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin-left: 35px;
      margin-right: 10px;
      margin-top: ${props => props.opened ? '10px' : '0'};
      margin-bottom: ${props => props.opened ? '5px' : '0'};
      max-height: ${props => props.opened ? '100%' : '0'};
      gap: 8px;
  }
`

const SearchContainer = styled('div')`
  width: fit-content;
`

const SearchOpener = styled('div')`
  width: fit-content;
  position: absolute;
  right: 20px;
  z-index: 5;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
`

const SearchBarContainer = styled('div')`
  display: block;
   width: 100%;
   position: absolute;
   top: calc(100% + 1px);
   left: 0;
   right: 0;
   z-index: -1;
   background: #FFFFFF;
   box-shadow: 0 0.25rem 0.9375rem #00000033;
   transform: scaleY(${props => props.expanded ? '1' : '0'});
   transform-origin: top;
   opacity: ${props => props.expanded ? '1' : '0'};
   transition: all 0.2s ease-in-out;
   padding: ${props => props.expanded ? '25px' : '0'};
   pointer-events: ${props => props.expanded ? 'auto' : 'none'};
   height: auto;
   overflow: hidden;
`

const SearchInputWrapper = styled('div')`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
`

const SearchInput = styled('input')`
  width: 100%;
  border: none;
  height: 35px;
  width: 100%;
  background-color: #f8f8f8;
  padding: 5px 50px;
  font-size: 20px;
  
  &::placeholder {
  color: #bebebe;
  user-select: none;
}
`

const SearchSubmitBtn = styled('button')`
  border: 0;
  padding: 10px 10px 10px 15px;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

const SearchCloseBtn = styled('button')`
  border: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  padding: 0;
`


export {
  DesktopNavigation,
  MobileNavigation,
  DesktopNavigationWrapper,
  NavigationLinkWrapper,
  NavigationIconWrapper,
  DesktopNavigationLink,
  DesktopCategoryNavigationContainer,
  DesktopDropdownContainer,
  DesktopInnerDropdownContainer,
  DropdownIcon,
  CategoryText,
  SubcategoryText,
  MobileDropdownOpener,
  MobileNavigationWrapper,
  MobileDropdownContent,
  MobileRowContainer,
  MobileInnerDropdown,
  IconTextLink,
  SearchContainer,
  SearchOpener,
  SearchBarContainer,
  SearchInputWrapper,
  SearchInput,
  SearchSubmitBtn,
  SearchCloseBtn,
};
