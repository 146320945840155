import styled from 'styled-components';

export const Video = styled('video')`
  display: block;
  position: absolute;
  inset: 0;
  object-fit: cover;
`;

export const EmbedVideo = styled('div')`
  display: block;
  width: 100%;
  height: 500px;
  object-fit: cover;

  ${props => props.isFeatureCarousel && `
    position: absolute;
    top: 0;
    height: 100%;
  `}
`;
