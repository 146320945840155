import {
  NavigationIconWrapper,
  CategoryText,
  IconTextLink,
} from '../../blog-navigation.styles';
import { Image as BuilderImage } from '@builder.io/react';

const IconText = ({ item }) => {
  return (
    <IconTextLink href={item.navLink} className='navigation-link-wrapper'>
      {item?.image && (
        <NavigationIconWrapper>
          <BuilderImage
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            lazy='false'
            image={item?.image}
          />
        </NavigationIconWrapper>
      )}
      <CategoryText>{item?.navText}</CategoryText>
    </IconTextLink>
  );
};

export default IconText;
