import { useState, Fragment } from 'react';
import {
  MobileNavigation,
  MobileDropdownOpener,
  MobileNavigationWrapper,
  MobileDropdownContent,
  CategoryText,
  MobileRowContainer,
} from '../../blog-navigation.styles';

import SearchBar from '../search/search-bar';

import InnerRowDropdown from './inner-row-dropdown';
import SVG from '@glowforge/gf-styled/src/components/svg/svg';
import IconText from './icon-text';

const MobileNav = ({ blogNavItems, mobileDropdownOpenerText }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <MobileNavigation className='mobile-navigation'>
      <MobileDropdownOpener
        expanded={expanded}
        onClick={() => setExpanded((state) => !state)}>
        {mobileDropdownOpenerText && <CategoryText>{mobileDropdownOpenerText}</CategoryText>}
        <SVG
          id='chevron-down'
          fill='#000'
          stroke='#000'
          width={10}
          height={10}
        />
      </MobileDropdownOpener>
      <MobileNavigationWrapper
        className='mobile-navigation-wrapper'
        expanded={expanded}>
        <MobileDropdownContent className='mobile-dropdown-content'>
          {blogNavItems?.map((item,index) => {
            const isSubNav = item?.subnav ?? false;
            return (
              <Fragment key={item?.navLink + item?.navText + index}>
                {isSubNav ? (
                  <InnerRowDropdown
                    item={item}
                    isSubNav={isSubNav}
                  />
                ) : (
                  <MobileRowContainer>
                    <IconText item={item} />
                  </MobileRowContainer>
                )}
              </Fragment>
            );
          })}
        </MobileDropdownContent>
      </MobileNavigationWrapper>
      <SearchBar setExpanded={setExpanded} />
    </MobileNavigation>
  );
};

export default MobileNav;
