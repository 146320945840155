import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../svg';
import { AdditionalInfoWrapper, Grouping, LinkWrapper, LogoRow, LoveWrapper, Sales, Wrapper } from './footer.styles';
import { ContactSection, LegalSection, LinkGrouping, SocialSection } from './footer-sections';
import { GridContainer } from '../grid-container';

const Footer = ({
	globalFooterContact,
	globalFooterLegal,
	globalFooterLinks,
	globalFooterLogo,
	globalFooterSales,
	globalFooterSignature,
	globalFooterSocialRebrand,
	globalFooterSignUpForEmails
}) => {
	// Notes:
	// privacy policy is still served from ecomm-core, so don't attempt to use react-isomorphic-link
	return (
    <GridContainer variant='full' as='footer'>
      <Wrapper variant='body'>
        <LinkWrapper>
          <LinkGrouping content={globalFooterLinks} />
          <Grouping>
            <ContactSection content={globalFooterContact} />
            <Sales>
              <ContactSection content={globalFooterSales} />
            </Sales>
          </Grouping>
          <SocialSection content={globalFooterSocialRebrand} signUpBtnContent={globalFooterSignUpForEmails}  />
        </LinkWrapper>
        <AdditionalInfoWrapper>
          <LogoRow>
            {globalFooterLogo ? <SVG id='glowforge-logo' height='37px' noWidth stroke='transparent'/> : null}
						<LoveWrapper sections={globalFooterSignature?.listItems || []} />
          </LogoRow>
          <LegalSection content={globalFooterLegal} />
        </AdditionalInfoWrapper>
      </Wrapper>
    </GridContainer>
	);
};

Footer.propTypes = {
	// Contentful driven props -
	//    Footer is populated from the raw contentful `global-footer`
	//    section data
	globalFooterContact: PropTypes.objectOf(PropTypes.any),
	globalFooterLegal: PropTypes.objectOf(PropTypes.any),
	// globalFooterLinks: extracted from fields.content[{fields: {title, listItems}}]
	//  into fields.content[{title, items}] for only link items.
	//  This eventually gets applied as the grouped links for the left-side sitemap under
	//  .marketing > .links
	globalFooterLinks: PropTypes.arrayOf(PropTypes.any),
	// globalFooterLogo: extracted from an entry in fields.content that has
	//   fields.id: 'glowforge-footer-logo', with the value from fields.svgAsset.
	//   Used in the companyInfo logo div
	globalFooterLogo: PropTypes.objectOf(PropTypes.any),
	globalFooterSales: PropTypes.objectOf(PropTypes.any),
	globalFooterSignature: PropTypes.objectOf(PropTypes.any),
	globalFooterSocialRebrand: PropTypes.objectOf(PropTypes.any),
};

Footer.defaultProps = {
	// Requiring any props that we plan to have from contentful is unhelpful
	// If there is an issue with that one part, then it'll break then entire
	// page looking for the required props
	globalFooterContact: null,
	globalFooterLegal: null,
	globalFooterLinks: null,
	globalFooterLogo: null,
	globalFooterSales: null,
	globalFooterSignature: null,
	globalFooterSignature: null,
	globalFooterSocialRebrand: null,
};

export default Footer;
