import PropTypes from 'prop-types';
import DesktopNav from './components/desktop-nav/desktop-navigation';
import MobileNav from './components/mobile-nav/mobile-navigation';

const BlogNavigation = ({ ...props }) => {
  const { blogNavigation, mobileDropdownOpenerText } = props || {};
  const { blogNavItems } = blogNavigation?.value?.data || {};
  return (
    <>
      {blogNavItems?.length > 0 && (
        <>
          <DesktopNav blogNavItems={blogNavItems} />
          <MobileNav blogNavItems={blogNavItems} mobileDropdownOpenerText={mobileDropdownOpenerText} />
        </>
      )}
    </>
  );
};

BlogNavigation.propTypes = {
  blogNavItems: PropTypes.arrayOf(PropTypes.shape({})),
};

BlogNavigation.defaultProps = {
  blogNavItems: [],
};

export default BlogNavigation;
